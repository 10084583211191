<meta content="width=device-width, initial-scale=1" name="viewport" />
<div id="content">
  <mat-accordion class="example-headers-align" multi>

    <!-- Options button -->
    <button id="more-icon" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon >more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button [routerLink]="['/recipes/add']" mat-menu-item>
        <mat-icon>add</mat-icon>
        <span>New Recipe</span>
      </button>
      <button [routerLink]="['/recipes/link/add']" mat-menu-item>
        <mat-icon>add</mat-icon>
        <span>New Link</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="sort">
        <mat-icon>sort</mat-icon>
        <span>Sort</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="filter">
        <mat-icon>filter_list</mat-icon>
        <span>Filter</span>
      </button>
    </mat-menu>

    <mat-menu #sort="matMenu">
      <button *ngFor="let option of sortOptions" (click)="onSortClick(option)" mat-menu-item>
        <mat-icon *ngIf="sortValue == option">check</mat-icon>
        <mat-icon *ngIf="sortValue != option"></mat-icon>
        {{option}}
      </button>
    </mat-menu>
    
    <mat-menu #filter="matMenu">
      <button *ngFor="let option of filterOptions; let i = index;" (click)="onFilterClick(i)"  mat-menu-item>
        <mat-icon *ngIf="filterValue == i">check</mat-icon>
        <mat-icon *ngIf="filterValue != i"></mat-icon>
        {{option}}
      </button>
    </mat-menu>
      <!-- End Options Button  -->

    <!-- Categories loop starts here -->
    <mat-expansion-panel style="background-color: rgb(208, 204, 128);" *ngFor="let category of categories" expanded="true">
      <mat-expansion-panel-header class="category-header">
        <mat-panel-title class="category">
          {{category}} Recipes
        </mat-panel-title>
        <mat-panel-description>
          <!-- <mat-icon>account_circle</mat-icon> -->
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-selection-list>
        <span *ngFor="let recipe of recipes | filterCategory:category | filterAuthor:filterValue:users:currentUserId">
          <div *ngIf="smallScreen">
            <mat-list-item class="recipe-item" *ngIf="!recipe.isLink" (click)="openRecipeDialog(recipe.id)" >
              {{recipe.name}}
            </mat-list-item>
            <mat-list-item class="recipe-item" *ngIf="recipe.isLink" (click)="openLinkDialog(recipe.id)" >
              {{recipe.name}}
            </mat-list-item>
          </div>
          <div *ngIf="!smallScreen">
            <mat-list-item class="recipe-item" *ngIf="!recipe.isLink" routerLinkActive="active" [routerLink]="['/recipes', recipe.id]" >
              {{recipe.name}}
            </mat-list-item>
            <mat-list-item class="recipe-item" *ngIf="recipe.isLink" routerLinkActive="active" [routerLink]="['/recipes/link', recipe.id]" >
              {{recipe.name}}
            </mat-list-item>
          </div>
      </span>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- <app-recipe-detail id="detail"></app-recipe-detail> -->
  <span  id="detail">
    <router-outlet></router-outlet>
  </span>
</div>