import { Component, OnInit } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

export interface UserData {
  name: string;
  email: string;
  recipeCount: number;
  lastActive: string;
  role: string;
  groupName: string;
}



@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  ELEMENT_DATA: UserData[] = [];
  ELEMENT_DATA_COPY: UserData[] = [];
  initializing = true;

  displayedColumns: string[] = ['name', 'email', 'recipeCount', 'linkCount', 'lastActive', 'role', 'groupName', 'delete'];
  dataSource = this.ELEMENT_DATA;

  roleList = ['Admin', 'User', 'Locked', 'Disabled'];

    //Reporting:
    totalRecipes: number = 0;
    linkCount: number = 0;
    linkCountPercent: string = '0';
    manualCount: number = 0;
    manualCountPercent: string = '0';
    totalGroups: number = 0;
    activeUsers: number = 0;
    groupSet: Set<string> = new Set<string>();

  constructor(private authService : AuthService) { }

  ngOnInit(): void {
    this.authService.GetAllUserData().then(this.loadData.bind(this));
  }

  loadData(data : any[]){
    this.dataSource = data;
    this.ELEMENT_DATA_COPY = [];
    for(let cur of data){
      let curUser = structuredClone(cur);
      this.ELEMENT_DATA_COPY.push(curUser)
      //Do some other work while we're here:
      this.totalRecipes += cur.recipeCount;
      this.totalRecipes += cur.linkCount;
      this.linkCount += cur.linkCount;
      this.manualCount += cur.recipeCount;
      this.groupSet.add(cur.groupName)
      var d = new Date()
      var itemDate = Date.parse(cur.lastActive)
      console.log(itemDate);
      if(!isNaN(itemDate) && new Date(itemDate) > new Date(d.setDate(d.getDate() - 30))){
        this.activeUsers++;
      }
    }
    this.groupSet.delete('');
    this.totalGroups = this.groupSet.size;
    let percent = 100 * (this.linkCount / this.totalRecipes);
    this.linkCountPercent = percent.toFixed(1)
    percent = 100 - 100 * (this.linkCount / this.totalRecipes);
    this.manualCountPercent = percent.toFixed(1)
    this.initializing = false;
  }

  updateRole(userData : UserData){
    let prevData = this.ELEMENT_DATA_COPY.find((element) => element.email == userData.email);
    if(prevData == null){
      alert('internal issue: prevData not found')
      return;
    }
    var prevRole = prevData.role;
    if (userData.role == prevRole){
      return;
    }
    this.authService.changeRole(userData.email, userData.role).then(res => {
      alert(res.message)
      if(res.status != 200){
        userData.role = prevRole;
      }
      else {
        prevData.role = userData.role;
      }
    })
  }

  deleteClick(data : UserData){
    Swal.fire({
      title: "Delete?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.authService.deleteUser(data.email).then(res => {
          if(res.status == 200){
            Swal.fire("Deleted", res.message, "success");
            let prevData = this.ELEMENT_DATA_COPY.findIndex((element) => element.email == data.email);
            this.dataSource.splice(prevData, 1);
            this.dataSource = this.dataSource.slice();
            this.ELEMENT_DATA_COPY.splice(prevData, 1);
          }
          else {
            Swal.fire("Unable to Delete", res.message, "info");
          }
        })
      } else if (result.isDenied) {

      }
    });
  }

}
