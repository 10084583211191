<mat-drawer-container  [ngClass]="(!isLoggedIn && !aboutPage) ? 'gray' : ''" id="nav-bar" hasBackdrop="false">
  <mat-drawer  #drawer [ngClass]="(!isLoggedIn && !aboutPage) ? 'gray' : ''" [opened]="isOpened" mode="over">
    <mat-nav-list id="navLinkList">
        <a mat-list-item  [disabled]="!isLoggedIn" [ngClass]="!isLoggedIn ? 'gray' : ''" class="navListLinks" routerLink="/recipes"><span class="navListLinks">Recipes</span></a>
        <!-- <a mat-list-item class="navListLinks" routerLink="/group"><span class="navListLinks">Group Recipes</span></a> -->
        <a *ngIf="isAdmin" mat-list-item [disabled]="!isLoggedIn" [ngClass]="!isLoggedIn ? 'gray' : ''" class="navListLinks" routerLink="/admin"><span class="navListLinks">Admin</span></a>
        <a mat-list-item [disabled]="!isLoggedIn" [ngClass]="!isLoggedIn ? 'gray' : ''" class="navListLinks" routerLink="/about"><span class="navListLinks">About</span></a>
        <a mat-list-item [disabled]="!isLoggedIn" [ngClass]="!isLoggedIn ? 'gray' : ''" class="navListLinks" routerLink="/account"><span class="navListLinks">Account</span></a>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
    <p></p>
    <p></p>
    <p></p>
  </mat-drawer-content>
</mat-drawer-container>

<!-- Toolbar -->
<div class="toolbar" role="banner">
    <mat-icon [hidden]="!isLoggedIn" style="color:black; width:40px; cursor: pointer; text-align: right;" (click)="isOpened = !isOpened">menu</mat-icon>
  <img
    width="40"
    alt="Angular Logo"
    src="assets/Mauvee-white.jpg" 
  /> <!--Image generated by Bing-->
  <span>Grandma Ellinor's Recipes</span>
</div>

<div class="content" role="main">



