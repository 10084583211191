<div *ngIf="!initializing && !editMode" class="scrollable-content"  id="content">
    <h2 mat-dialog-title>{{recipe?.name}}</h2>
    <mat-dialog-content class="mat-typography">
      <p class="ingredient" *ngFor="let ingredient of recipe?.ingredients">{{ingredient.amount}} {{ingredient.name}}</p>
      <hr>
      <p *ngFor="let step of recipe?.steps; let i = index">{{i + 1}}. {{step.description}}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button *ngIf = "recipe?.user == user?.id?.toString()" (click)="editMode = true" mat-button>EDIT</button>
      <button *ngIf="!deleteButtonVerify && recipe?.user == user?.id?.toString()" (click)="deleteButtonVerify = true;" mat-button>DELETE</button>
      <button color="warn" *ngIf="deleteButtonVerify && recipe?.user == user?.id?.toString()" (click)="deleteRecipe()" mat-dialog-close mat-button>DELETE</button>
      <span style="margin-left: auto; color: rgb(125, 124, 98); font-size: 20px;" >{{recipeAuthorName}}</span>
    </mat-dialog-actions>
    <button style="position: absolute; right: 10px; top: 10px;" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>

<!-- Recipe Edit Code (Mobile only) -->

<div *ngIf="!initializing && editMode" class="scrollable-content" id="editContent">
  <mat-dialog-content class="example-container">
      <form [formGroup]="recipeForm" (ngSubmit)="submitRecipe()">
        <!-- <mat-checkbox [formControl]="hideRequiredControl">Hide required marker</mat-checkbox> -->
        <div class="example-form-fields">
          <!-- Title -->
          <mat-form-field
              [hideRequiredMarker]="hideRequiredControl.value"
              [floatLabel]="getFloatLabelValue()"
              style="display:block">
            <input matInput placeholder="Title" formControlName="name">
            <!-- <div *ngIf="!recipeForm.get('name')?.valid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)"
              class="alert">error</div> -->
              <mat-hint *ngIf="!recipeForm.get('name')?.valid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)">Error</mat-hint>
          </mat-form-field>

          <!-- Category -->
          <mat-form-field
           [hideRequiredMarker]="hideRequiredControl.value"
           [floatLabel]="getFloatLabelValue()">
            <mat-select 
            formControlName="category" 
            >
              <mat-option *ngFor="let category of categories" selected="true" value={{category}}>{{category}}</mat-option>
            </mat-select>
            <mat-label>
              <!-- <mat-icon>favorite</mat-icon>  -->
              <!-- <strong> Fancy</strong>  -->
              <em> Category</em></mat-label>
          </mat-form-field>

          <!-- Ingredients -->
            <div cdkDropList  (cdkDropListDropped)="dropIngredient($event)" class="example-list">
              <div formArrayName="ingredients">
                <div class="example-box"  *ngFor="let ingredientCtl of ingredients.controls; let i = index" [formGroupName]="i" cdkDrag>
                  <mat-form-field
                    class="quantity"
                    [hideRequiredMarker]="hideRequiredControl.value">
                    <input matInput placeholder="Quantity" formControlName="amount">
                  </mat-form-field>
                  <mat-form-field
                    class="ingredient"
                    [hideRequiredMarker]="hideRequiredControl.value">
                    <!-- <input matInput placeholder="Ingredient" formControlName="name"> -->
                    <textarea col="40" rows="5" class="ingredient-text" matInput placeholder="Ingredient" formControlName="name"></textarea>
                  </mat-form-field>
                  <!-- drag element -->
                  <div style="position: relative; display: block; height: 50px;">
                  <div class="example-handle" style="position: absolute; right: 50px; bottom: 25px; margin-top:10px;" cdkDragHandle>
                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </div>
                  <button style="position: absolute; left: 50px; bottom: 20px;" type="button" (click)="deleteIngredient(i)" mat-icon-button color="warn" aria-label="Delete icon">
                    <mat-icon>delete</mat-icon>
                  </button>
                  </div>
                </div>
            </div>
          </div>
          <div style="display: block; width: 100%; text-align: center;">
            <button type="button" (click)="addIngredient()" style="margin: auto" mat-icon-button color="primary" aria-label="Ingredient add button">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <!-- end ingredient section -->
          <hr>
          <!-- Steps section with moveable list -->
            <div cdkDropList class="example-list" (cdkDropListDropped)="dropStep($event)">
              <div formArrayName="steps">
                <div class="example-box step-box" *ngFor="let stepCtl of steps.controls; let i = index"  [formGroupName]="i" cdkDrag>
                  <mat-form-field
                    class="step-content"
                    [hideRequiredMarker]="hideRequiredControl.value">
                    <textarea col="40" rows="5" class="step-text" matInput placeholder="Step" formControlName="description"></textarea>
                  </mat-form-field>
                  
                  <div style="position: relative; display: block; height: 50px;">
                  <!-- drag element -->
                  <div class="example-handle" style="position: absolute; right: 50px; bottom: 25px; margin-top:10px;" cdkDragHandle>
                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </div>
                  <button type="button"  style="position: absolute; left: 50px; bottom: 20px;" (click)="deleteStep(i)" mat-icon-button color="warn" aria-label="Example icon button with a home icon">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                </div>
            </div>
          </div>
          <div style="display: block; width: 100%; text-align: center;">
            <button type="button" (click)="addStep()" style="margin: auto" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <hr>
          <!-- Save and Cancel buttons -->
        </div>
        <mat-dialog-actions>
          <button [disabled]="!recipeForm.valid" type="submit" mat-button>SAVE CHANGES</button>
          <button type="button" (click)="editMode = false" mat-button>CANCEL</button>
        </mat-dialog-actions>
      </form>
  </mat-dialog-content>

  </div>