import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { recipeService } from 'src/app/services/recipe.service';
import { Recipe } from 'src/app/shared/recipe.model';
import { User } from 'src/app/shared/user.model';

@Component({
  selector: 'app-link-detail',
  templateUrl: './link-detail.component.html',
  styleUrls: ['./link-detail.component.css']
})
export class LinkDetailComponent implements OnInit {

  recipe: Recipe;
  user: User | null;
  deleteButtonVerify: boolean = false;
  initializing : boolean = true;
  recipeAuthorName : string;

  constructor(private recipeService: recipeService, private router: Router, private route: ActivatedRoute, private auth: AuthService) {
    
   }

  ngOnInit(): void {
    this.recipeService.getRecipe(+this.route.snapshot.params['id'], true).then(this.finishInit.bind(this));
  }

  async finishInit(value : Recipe){
    this.recipe = value;
    this.route.params.subscribe(
      (params) => {
        this.recipeService.getRecipe(params['id'], true).then((value : Recipe) => this.recipe = value);
        this.deleteButtonVerify = false;
      }
    )
    this.user = await this.auth.getCurrentUser();
    let groupUsers = await this.auth.getGroupUsers();
    this.recipeAuthorName = this.getUserFromId(+this.recipe.user, groupUsers).firstName[0] + ' ' + this.getUserFromId(+this.recipe.user, groupUsers).lastName;
    this.initializing = false;
  }

  getUserFromId(id : number, groupUsers : User[]){
    for(let cur of groupUsers){
      if(cur.id == id){
        return cur;
      }
    }
    return new User(0, '', '', '', '', '', '','');
  }

  deleteRecipe(){
    this.recipeService.deleteRecipe(this.recipe.id, true);
    this.router.navigate([]);
    this.router.navigate(['recipes']);
  }

  goToLink(){
    window.open(this.recipe.link, '_blank');
  }

}
