import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/shared/user.model';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  public currentUser : User
  public firstName : string = '';
  public lastName : string = '';
  public role : string = '';
  public groupName : string = '';
  public loading : boolean = false;

  constructor(private auth : AuthService, private router : Router) { }

  ngOnInit(): void {
    this.auth.getCurrentUser().then(this.initializeCallback.bind(this));
  }

  initializeCallback(value : User | null){
    let user = value;
    if (user == null){
      this.currentUser = new User(0, '', '', '', '', '', '','');
    } else {
      this.currentUser = user;
      this.firstName = this.currentUser.firstName;
      this.lastName = this.currentUser.lastName;
      this.role = this.currentUser.role;
      this.groupName = this.currentUser.group
    }
  }

  logoutBtnClick(){
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  updateGroup(name : string){
    this.loading = true;
    this.auth.setGroup(name).then(this.updateGroupCallback.bind(this));
  }

  updateGroupCallback(value : any){
    this.loading = false;
    if(value.success){
      this.currentUser.group = value.group;
      alert(value.message)
    } else {
      alert(value.message);
    }
  }

  updatePassword(oldPassword : string, newPassword : string){
    if(oldPassword != '' && newPassword != ''){
      this.loading = true;
      this.auth.updatePassword(oldPassword, newPassword).then(this.updatePasswordCallback.bind(this));
    }
  }

  updatePasswordCallback(value : any){
    this.loading = false;
    if(value.success){
      alert('Password updated');
    } else {
      alert(value.message);
    }
  }

}
