import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MyRecipesComponent } from './my-recipes/my-recipes/my-recipes.component';
import { GroupRecipesComponent } from './group-recipes/group-recipes/group-recipes.component';
import { AdminComponent } from './admin/admin/admin.component';
import { AboutComponent } from './about/about/about.component';
import { AccountComponent } from './account/account/account.component';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import { RecipeDetailComponent } from './my-recipes/recipe-detail/recipe-detail.component';
import {MatCardModule} from '@angular/material/card';
import { recipeService } from './services/recipe.service';
import { RecipeEditComponent } from './recipes/recipe-edit/recipe-edit.component';
import { FilterCategoryPipe } from './pipes/filter-category.pipe';

import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { LinkDetailComponent } from './my-recipes/link-detail/link-detail.component';
import { LinkEditComponent } from './recipes/link-edit/link-edit.component';
import { FilterAuthorPipe } from './pipes/filter-author.pipe';
import { RecipeAddComponent } from './recipes/recipe-add/recipe-add.component';
import { LinkAddComponent } from './recipes/link-add/link-add.component';
import { LoginComponent } from './account/login/login.component';
import { SignupComponent } from './account/signup/signup.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import {MatDialogModule} from '@angular/material/dialog';
import { RecipeDetailDialogComponent } from './my-recipes/recipe-detail/recipe-detail-dialog/recipe-detail-dialog.component';
import { LinkDetailDialogComponent } from './my-recipes/link-detail/link-detail-dialog/link-detail-dialog.component';
import { MatTableModule } from '@angular/material/table'  
import { RecipesVizComponent } from './admin/recipes-viz/recipes-viz.component';
import { LoginsVizComponent } from './admin/logins-viz/logins-viz.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  exports: [
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatMenuModule,
    MatTooltipModule],
  declarations: [
    AppComponent,
    MyRecipesComponent,
    GroupRecipesComponent,
    AdminComponent,
    AboutComponent,
    AccountComponent,
    RecipeDetailComponent,
    RecipeDetailDialogComponent,
    RecipeEditComponent,
    FilterCategoryPipe,
    LinkDetailComponent,
    LinkDetailDialogComponent,
    LinkEditComponent,
    FilterAuthorPipe,
    RecipeAddComponent,
    LinkAddComponent,
    LoginComponent,
    SignupComponent,
    RecipesVizComponent,
    LoginsVizComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatMenuModule,
    HttpClientModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule
  ],
  providers: [recipeService, 
    {provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
