<!-- Generously contributed by ChatGPT -->
<div class="signup-container">
    <mat-card class="signup-card">
      <mat-card-header>
        <mat-card-title>Sign Up</mat-card-title>
        <p class="error">{{errorMessage}}</p>
      </mat-card-header>
      <mat-card-content>
        <form #signupform="ngForm">
          <mat-form-field class="full-width">
            <input matInput placeholder="Email" [(ngModel)]="email" name="email" required email>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput type="password" placeholder="Password" [(ngModel)]="password" name="password" required>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="First Name" [(ngModel)]="firstName" name="firstName" required>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput placeholder="Last Name" [(ngModel)]="lastName" name="lastName" required>
          </mat-form-field>
          <div id="button-ctr">
            <button *ngIf="!loading" mat-raised-button [disabled]="!signupform.form.valid" (click)="signup()">Sign Up</button>
            <button *ngIf="loading" class="loading-btn" mat-button ><img class="loading-gif" src="assets/Rhombus.gif"></button>
            <button type="button" id="return-to-login-btn" mat-raised-button [routerLink]="['/login']" >Return to Login</button>
            <button type="button" id="about-btn" mat-button [routerLink]="['/about']" >What is Grandma Ellinor's Recipes?</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  
