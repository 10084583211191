import { Ingredient } from "./ingredient.model";
import { Step } from "./step.model";

export class Recipe {
    constructor(public id: number,
                public name: string, 
                public isLink: boolean,
                public link: string,
                public ingredients: Ingredient[], 
                public steps: Step[], 
                public created: Date, 
                public lastUpdated: Date,
                public user: string,
                public category: string){}

    // generateIngredientId(){
    //     let max = -1;
    //     for(let current of this.ingredients){
    //         if (current.id > max){
    //             max = current.id
    //         }
    //     }
    //     return max + 1;
    // }

    // generateStepId(){
    //     let max = -1;
    //     for(let current of this.steps){
    //         if (current.id > max){
    //             max = current.id
    //         }
    //     }
    //     return max + 1;
    // }
}