import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { recipeService } from 'src/app/services/recipe.service';
import { Recipe } from 'src/app/shared/recipe.model';

import {FloatLabelType} from '@angular/material/form-field';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Ingredient } from 'src/app/shared/ingredient.model';
import { Step } from 'src/app/shared/step.model';



@Component({
  selector: 'app-recipe-edit',
  templateUrl: './recipe-edit.component.html',
  styleUrls: ['./recipe-edit.component.css']
})
export class RecipeEditComponent implements OnInit {
  //recipe: Recipe;
  recipeId: number;
  recipeCreated: Date;
  recipeUser: string;
  categories: string[];
  hideRequiredControl = new FormControl(false);
  recipeForm: FormGroup = new FormGroup({name: new FormControl(), category: new FormControl(), ingredients: new FormControl(), steps: new FormControl()}); //placeholder to wait for async result.
  initializing: boolean = true;

  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  constructor(private recipeService: recipeService, private router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) {
    
   }

  ngOnInit(): void {
    //this.recipeService.getRecipes().then((res) => {console.log(res)})
    this.route.params.subscribe(
      (params) => {
        this.recipeId = +params['id'];
        this.initForm();
      }
    )
    
  }

  async initForm(){
    let recipe = await this.recipeService.getRecipe(this.recipeId, false);
    //Save some data for when recipe gets updates.
    this.recipeCreated = recipe.created;
    this.recipeUser = recipe.user;
    this.categories = this.recipeService.getCategories();

    let recipeIngredients = new FormArray<any>([]);
    let recipeSteps = new FormArray<any>([]);

    for(let ingredient of recipe.ingredients){
      recipeIngredients.push(new FormGroup({
        'amount' : new FormControl(ingredient.amount),
        'name' : new FormControl(ingredient.name, Validators.required)
      }));
    }

    for(let step of recipe.steps){
      recipeSteps.push(new FormGroup({
        'description' : new FormControl(step.description, Validators.required)
      }));
    }

    this.recipeForm = new FormGroup({
      'name' : new FormControl(recipe.name, Validators.required),
      'category' : new FormControl(recipe.category, Validators.required),
      'ingredients' : recipeIngredients,
      'steps' : recipeSteps
    })
    this.initializing = false;
  }

  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  dropIngredient(event: CdkDragDrop<string[]>) {
    this.move((<FormArray>this.recipeForm.get('ingredients')), event.previousIndex, event.currentIndex);
  }

  dropStep(event: CdkDragDrop<string[]>) {
    this.move((<FormArray>this.recipeForm.get('steps')), event.previousIndex, event.currentIndex);
  }

  deleteIngredient(index: number){
    (<FormArray>this.recipeForm.get('ingredients')).removeAt(index);
  }

  deleteStep(index: number){
    (<FormArray>this.recipeForm.get('steps')).removeAt(index);
  }

  addIngredient(){
    (<FormArray>this.recipeForm.get('ingredients')).push( new FormGroup({
        'amount' : new FormControl(''),
        'name' : new FormControl('', Validators.required)
    }))
  }

  addStep(){
    (<FormArray>this.recipeForm.get('steps')).push( new FormGroup({
      'description' : new FormControl('', Validators.required)
    }))
  }

  get ingredients() {
    return this.recipeForm.get('ingredients') as FormArray;
  }

  get steps() {
    return this.recipeForm.get('steps') as FormArray;
  }

  submitRecipe(){
    let ingredients : Ingredient[] = [];
    let steps : Step[] = [];
    (<FormArray>(this.recipeForm.get("ingredients"))).controls.forEach(function (curr, i) {
      ingredients.push(new Ingredient(curr.value["name"], curr.value["amount"]));
    });
    (<FormArray>(this.recipeForm.get("steps"))).controls.forEach(function (curr, i) {
      steps.push(new Step(curr.value["description"]));
    })

    let recipe : Recipe = new Recipe(this.recipeId, this.recipeForm.get("name")?.value, false, '', ingredients, steps, this.recipeCreated, new Date(), this.recipeUser, this.recipeForm.get("category")?.value);
    this.recipeService.updateRecipe(recipe, false);
    this.router.navigate([]);
    this.router.navigate(['recipes', this.recipeId]);
  }

  move(list : FormArray, prevIndex : number, newIndex : number){
    if(prevIndex == newIndex) return;
    if(prevIndex < newIndex){
      let ctl : AbstractControl = list.at(prevIndex);
      while(prevIndex < newIndex){
        list.setControl(prevIndex, list.at(prevIndex + 1));
        prevIndex = prevIndex + 1;
      }
      list.setControl(prevIndex, ctl);
    }
    if(prevIndex > newIndex){
      let ctl : AbstractControl = list.at(prevIndex);
      while(prevIndex > newIndex){
        list.setControl(prevIndex, list.at(prevIndex - 1));
        prevIndex = prevIndex - 1;
      }
      list.setControl(prevIndex, ctl);
    }
  }
}
