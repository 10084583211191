<div class="login-container">
    <mat-card class="login-card">
      <mat-card-header>
        <mat-card-title>Login</mat-card-title>
        <p class="error">{{errorMessage}}</p>
      </mat-card-header>
      <mat-card-content>
        <form #loginform="ngForm">
          <mat-form-field class="full-width">
            <input matInput placeholder="Email" [(ngModel)]="email" name="email" required email>
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput type="password" placeholder="Password" [(ngModel)]="password" name="password" required>
          </mat-form-field>
          <div id="button-ctr">
            <button style="background-color: rgb(231, 231, 18); color:black" *ngIf="!loading" mat-raised-button [disabled]="!loginform.form.valid" color="primary" (click)="login()">Login</button>
            <button style="background-color: rgb(235, 235, 210);" *ngIf="loading" class="loading-btn" mat-button ><img class="loading-gif" src="assets/Rhombus.gif"></button>
            <button style="background-color: rgb(235, 235, 210);" type="button" id="register-button" mat-raised-button [routerLink]="['/signup']" >Register</button>
          </div>
          <button type="button" id="forgot-password-btn" mat-button (click)="forgotPassword()" >Forgot Password?</button>
          <button type="button" id="about-btn" mat-button [routerLink]="['/about']" >What is Grandma Ellinor's Recipes?</button>
          

          <!-- Sign on with Google, facebook, etc -->
          <!-- <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>facebook</mat-icon>
          </button>
          <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>Google</mat-icon>
          </button> -->
        </form>
      </mat-card-content>
    </mat-card>
  </div>
