import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { recipeService } from 'src/app/services/recipe.service';
import { Recipe } from 'src/app/shared/recipe.model';

import {FloatLabelType} from '@angular/material/form-field';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/shared/user.model';

@Component({
  selector: 'app-link-add',
  templateUrl: './link-add.component.html',
  styleUrls: ['./link-add.component.css']
})
export class LinkAddComponent implements OnInit {
  //recipe: Recipe;
  recipeCreated: Date;
  recipeUser?: string;
  categories: string[];
  hideRequiredControl = new FormControl(false);
  recipeForm: FormGroup;
  loading: boolean = false;

  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  constructor(private recipeService: recipeService, private auth : AuthService, private router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) {
    
   }

  ngOnInit(): void {
      this.initForm();
  }

  initForm(){
    this.auth.getCurrentUser().then(this.initializeCallback.bind(this));
  }

  initializeCallback(value : User | null){
    this.recipeCreated = new Date();
    this.recipeUser = value?.id.toString().toString();
    this.categories = this.recipeService.getCategories();

    this.recipeForm = new FormGroup({
      'name' : new FormControl('', Validators.required),
      'category' : new FormControl('', Validators.required),
      'link' : new FormControl('', Validators.required)
    })
  }

  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  async submitRecipe(){
    this.loading = true;
    let prefix = 'https://'
    let url = this.recipeForm.get("link")?.value.toLowerCase();
    url = url.includes('http') ? url : prefix.concat(url);
    let recipe : Recipe = new Recipe(-1, this.recipeForm.get("name")?.value, true, url, [], [], this.recipeCreated, new Date(), this.recipeUser ?? '', this.recipeForm.get("category")?.value);
    let linkId = await this.recipeService.createRecipe(recipe);
    this.router.navigate(['recipes/link', linkId]);

    if(linkId == 0) {
      this.loading = false;
      alert('Unable to create recipe');
    }
    else {
      this.loading = false;
      if(screen.width < 600) {
        this.router.navigate(['recipes']);
      } else {
        this.router.navigate([]);
      this.router.navigate(['recipes/link', linkId]);
      }
      
    }
  }
}
