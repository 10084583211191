import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about/about.component';
import { AdminComponent } from './admin/admin/admin.component';
import { GroupRecipesComponent } from './group-recipes/group-recipes/group-recipes.component';
import { MyRecipesComponent } from './my-recipes/my-recipes/my-recipes.component';
import { AccountComponent } from './account/account/account.component';
import { RecipeDetailComponent } from './my-recipes/recipe-detail/recipe-detail.component';
import { RecipeEditComponent } from './recipes/recipe-edit/recipe-edit.component';
import { LinkDetailComponent } from './my-recipes/link-detail/link-detail.component';
import { LinkEditComponent } from './recipes/link-edit/link-edit.component';
import { RecipeAddComponent } from './recipes/recipe-add/recipe-add.component';
import { LinkAddComponent } from './recipes/link-add/link-add.component';
import { LoginComponent } from './account/login/login.component';
import { SignupComponent } from './account/signup/signup.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {path: "recipes", component: MyRecipesComponent, canActivate:[AuthGuard], children: [
    {path: "edit/:id", component: RecipeEditComponent},
    {path: "link/edit/:id", component: LinkEditComponent},
    {path: "add", component: RecipeAddComponent},
    {path: "link/add", component: LinkAddComponent},
    {path: ":id", component: RecipeDetailComponent},
    {path: "link/:id", component: LinkDetailComponent}
  ]},
  {path: "group", component: GroupRecipesComponent, canActivate:[AuthGuard]},
  {path: "admin", component: AdminComponent, canActivate: [AuthGuard]},
  {path: "about", component: AboutComponent},
  {path: "account", component: AccountComponent, canActivate: [AuthGuard]},
  {path: "login", component: LoginComponent},
  {path: "signup", component: SignupComponent},
  {path: "**", redirectTo: 'recipes'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
