import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router'

import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { recipeService } from './services/recipe.service';
import { AuthService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'FloisandFoodiesUI';
  isOpened : boolean = false;
  isLoggedIn = true;
  isAdmin = false;
  aboutPage = false;

  constructor(private auth : AuthService, private router : Router){
    if( auth.isLoggedIn()){
      this.isLoggedIn = false;
    } else {
      this.isLoggedIn = true;
    }


    auth.LoggedOut.subscribe(() => {
      if( !auth.isLoggedIn()){
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
    })

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd){
        if( !auth.isLoggedIn()){
          this.isLoggedIn = false;
          if(val.url == '/about'){
            this.aboutPage = true;
          } else {
            this.aboutPage = false;
          }
        } else {
          this.isLoggedIn = true;
          this.aboutPage = false;
          auth.getCurrentUser().then(val => this.isAdmin = val?.role == 'Admin');
        }
      }

    })
  }

}
