import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { User } from '../shared/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl : string = "https://GrandmaEllinorsRecipes.com/api/User/"

  private currentUser : User | null;

  private groupUsers : User[] = [];

  public  LoggedOut : EventEmitter<boolean> = new EventEmitter();

  public LoggedIn : EventEmitter<boolean> = new EventEmitter();

  constructor(private http : HttpClient) { }

  async signUp(email : string, password : string, firstName : string, lastName : string) : Promise<boolean> {
    
    let res = await this.http.post<any>(this.baseUrl + 'register', {'Email' : email, 'Password' : password, 'FirstName' : firstName, 'LastName' : lastName}).toPromise();
    if(res.status == 200){
      this.currentUser = new User(res.userId, firstName, lastName, email, '', res.role, '','');
      this.storeToken(res.token);
      this.getGroupUsers();
      return true;
    } else {
      return false;
    }
  }

  async login(email : string, password : string) : Promise<boolean> {
    let res = await this.http.post<any>(this.baseUrl + 'authenticate', {'Email' : email, 'Password' : password}).toPromise();
    if(res.status == 200){
      this.currentUser = new User(res.userId, res.firstName, res.lastname, email, res.group, res.role, res.filterValue,res.sortValue);
      this.storeToken(res.token);
      this.getGroupUsers();
      this.LoggedIn.emit();
      return true;
    } else {
      return false;
    }
  }

  async getGroupUsers() {
    //if(this.groupUsers.length == 0){
      let res = await this.http.get<any>(this.baseUrl + 'getUsersInGroup').toPromise(); 
      this.groupUsers = [];
      for(let item of res){
          this.groupUsers.push(new User(item.userId, item.firstName, item.lastName, '', item.group, '', '', ''));
      }
    //}
    return this.groupUsers.slice();
  }

  logout() {
    this.currentUser = null;
    localStorage.clear();
   // localStorage.removeItem();
   this.LoggedOut.emit();
  }

  isLoggedIn() : boolean {
    this.getCurrentUser();
    return this.getToken() != null;

    // if(!!localStorage.getItem('token')){
    //   return true;
    // } else {
    //   return false;
    // }
  }

  async getCurrentUser(){
    console.log(this.currentUser == null)
    if(this.currentUser == null) {
      if(!!localStorage.getItem('token')){
        let res = await this.http.get<any>(this.baseUrl + 'GetUser?Token=' + localStorage.getItem('token')).toPromise(); 
        if(res.userId == ''){
          console.log('getcurrentuser no user id')
          localStorage.removeItem('token')
          return null;
        }
        this.currentUser = new User(res.userId, res.firstName, res.lastName, res.email, res.group, res.role, res.filterValue, res.sortValue);
        this.getGroupUsers();
      }
      else {
        return null;
      }
    }
    return new User(this.currentUser.id,  this.currentUser.firstName, this.currentUser.lastName, this.currentUser.email, this.currentUser.group, this.currentUser.role, this.currentUser.filter, this.currentUser.sort)
  }

  async updateFilterChoice(option : number){
    return await this.http.get<any>(this.baseUrl + 'UpdateFilterChoice?Option=' + option).toPromise();
  }

  async setGroup(name : string){
    if(name == ''){
      return await this.http.get<any>(this.baseUrl + 'RemoveGroup?').toPromise();
    }
    return await this.http.get<any>(this.baseUrl + 'UpdateGroup?GroupName=' + name).toPromise();
  }

  async updatePassword(oldPassword : string, newPassword : string){
    return await this.http.get<any>(this.baseUrl + 'UpdatePassword?OldPassword=' + oldPassword + '&NewPassword=' + newPassword).toPromise();
  }

  async forgotPassword(email : string){
    return await this.http.get<any>(this.baseUrl + 'ForgotPassword?Email=' + email).toPromise();
  }

  async GetAllUserData(){
    return await this.http.get<any>(this.baseUrl + 'GetAllUserData').toPromise();
  }

  async changeRole(email : string, role : string){
    return await this.http.post<any>(this.baseUrl + 'ChangeRole', {'Email' : email, 'Role' : role}).toPromise();
  }

  async deleteUser(email : string){
    return await this.http.post<any>(this.baseUrl + 'DeleteUser', {'Email' : email}).toPromise();
  }

  async getLoginsByYear(){
    return await this.http.get<any>(this.baseUrl + 'GetLoginsByYear').toPromise();
  }

  storeToken(tokenValue : string){
    localStorage.setItem('token', tokenValue)
  }

  getToken(){
    return localStorage.getItem('token')
  }
}
