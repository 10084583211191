import { HttpClient } from "@angular/common/http";
import { Ingredient } from "../shared/ingredient.model";
import { Recipe } from "../shared/recipe.model";
import { Step } from "../shared/step.model";
import { EventEmitter, Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { User } from "../shared/user.model";

@Injectable({
    providedIn: 'root'
  })
export class recipeService{

    private baseUrl : string = "https://GrandmaEllinorsRecipes.com/api/Recipe/"

    private recipes: Recipe[] = [];

    private bootingUp : boolean = true;

    public  recipeListChanged : EventEmitter<boolean> = new EventEmitter();
    
    constructor(private http : HttpClient, private auth : AuthService){
        auth.LoggedOut.subscribe((params) => {
            this.bootingUp = true;
            });
    }

    async getRecipes(){
        if(!this.bootingUp){
            return this.recipes;
        }
        let user = await this.auth.getCurrentUser();
        let res = await this.http.get<any>(this.baseUrl).toPromise(); 
        this.recipes = [];
        for(let item of res){
            let ingredients : Ingredient[] = [];
            for(let curr of item.ingredients){
                ingredients.push(new Ingredient(curr.name, curr.amount));
            }
            let steps : Step[] = [];
            for(let curr of item.steps){
                steps.push(new Step(curr.description));
            }
            this.recipes.push(new Recipe(item.recipeId, item.recipeName, item.isLink, item.link, ingredients, steps, item.createdDTM, item.lastUpdatedDTM, item.user, item.category));
        }
        this.bootingUp = false;
        return this.recipes;
    }

    async getRecipe(id: number, isLink: boolean){
        await this.getRecipes();
        let index = this.recipes.findIndex(r => r.id == id && r.isLink == isLink);
        let recipe = this.recipes[index];
        return new Recipe(recipe.id, recipe.name, recipe.isLink, recipe.link, recipe.ingredients.slice(), recipe.steps.slice(), recipe.created, recipe.lastUpdated, recipe.user, recipe.category);
    }

    async createRecipe(recipe: Recipe) : Promise<number>{
        let res = await this.http.post<any>(this.baseUrl + 'create', {'recipeName' : recipe.name, 'isLink' : recipe.isLink, 'link' : recipe.link, 'ingredients' : recipe.ingredients, 'steps' : recipe.steps, 'user' : recipe.user, 'category' : recipe.category}).toPromise();
        if(res.status == 200){
            this.recipes.push(new Recipe(res.recipeId,recipe.name,recipe.isLink,recipe.link,recipe.ingredients,recipe.steps,recipe.created,recipe.lastUpdated,recipe.user,recipe.category));
            this.recipes = this.recipes.slice(); //Triggers an update in recipe list
            this.recipeListChanged.emit();
            return res.recipeId;
        } else {
            return 0;
        }
    }

    deleteRecipe(recipeId: number, isLink: boolean){
        let index = this.recipes.findIndex(r => r.id == recipeId && r.isLink == isLink);
        let oldRecipe = this.recipes[index];
        if(isLink){
            this.http.post<any>(this.baseUrl + 'deleteLink/' + recipeId, {}).subscribe((res) => {
                if(res.status != 200){
                    alert('Unable to delete link: ' + res.message);
                    this.recipes.push(oldRecipe);
                    this.recipes.slice();
                }
                });
        }
        else {
            this.http.post<any>(this.baseUrl + 'delete/' + recipeId, {}).subscribe((res) => {
            if(res.status != 200){
                alert('Unable to delete recipe: ' + res.message);
                this.recipes.push(oldRecipe);
                this.recipes.slice();
            }
            });
        }
        this.recipes.splice(index, 1);
        this.recipes = this.recipes.slice(); //Triggers an update in recipe list
        this.recipeListChanged.emit();
    }

    //Override whatever the old recipe with this one. Finds old one based on the id
    updateRecipe(recipe: Recipe, isLink: boolean){
        let index = this.recipes.findIndex(r => r.id == recipe.id && r.isLink == isLink);
        let oldRecipe = this.recipes[index];
        this.http.post<any>(this.baseUrl + 'update', {'recipeId' : recipe.id, 'recipeName' : recipe.name, 'isLink' : recipe.isLink, 'link' : recipe.link, 'ingredients' : recipe.ingredients, 'steps' : recipe.steps, 'user' : recipe.user, 'category' : recipe.category}).subscribe((res) => {
            if(res.status != 200){
                alert('Unable to update recipe: ' + res.message);
                this.recipes[index] = oldRecipe;
                this.recipes.slice();
            }
            });
        this.recipes[index] = recipe;
        //Trigger recipe list update
        this.recipes = this.recipes.slice();
        this.recipeListChanged.emit();
    }
    
  async getRecipesByYear(){
    return await this.http.get<any>(this.baseUrl + 'GetRecipesByYear').toPromise();
  }

    //Gets a list of categories
    getCategories(){
        return ['20-minute',
                '5 Ingredient',
                'Beverage',
                'Bread',
                'Breakfast',
                'Casserole',
                'Crock Pot',
                'Dessert',                
                'Grill',
                'Instant Pot',
                'Keto',
                'Salad',
                'Sauces and Spreads',
                'Soup',
                'Stove Top',
                'Vegetarian'];
    }
}

