<mat-card *ngIf="!initializing" class="scrollable-content" id="content">
    <div class="example-container">
        <form [formGroup]="recipeForm" (ngSubmit)="submitRecipe()">
          <!-- <mat-checkbox [formControl]="hideRequiredControl">Hide required marker</mat-checkbox> -->
          <div class="example-form-fields">
            <!-- Title -->
            <mat-form-field
                [hideRequiredMarker]="hideRequiredControl.value"
                [floatLabel]="getFloatLabelValue()"
                style="display:block">
              <input matInput placeholder="Title" formControlName="name">
              <!-- <div *ngIf="!recipeForm.get('name')?.valid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)"
                class="alert">error</div> -->
                <mat-hint *ngIf="!recipeForm.get('name')?.valid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)">Error</mat-hint>
            </mat-form-field>

            <!-- Category -->
            <mat-form-field
             [hideRequiredMarker]="hideRequiredControl.value"
             [floatLabel]="getFloatLabelValue()">
              <mat-select 
              formControlName="category" 
              >
                <mat-option *ngFor="let category of categories" selected="true" value={{category}}>{{category}}</mat-option>
              </mat-select>
              <mat-label>
                <!-- <mat-icon>favorite</mat-icon>  -->
                <!-- <strong> Fancy</strong>  -->
                <em> Category</em></mat-label>
            </mat-form-field>

            <!-- Link text-->
            <mat-form-field style="display:block">
                <input matInput placeholder="Url" formControlName="link">
            </mat-form-field>

            <!-- Save and Cancel buttons -->
          </div>
          <mat-card-actions>
            <button routerLinkActive="active" [disabled]="!recipeForm.valid" type="submit" mat-button>SAVE CHANGES</button>
            <button type="button"  [routerLink]="['/recipes/link', recipeId]" mat-button>CANCEL</button>
          </mat-card-actions>
        </form>
      </div>

  </mat-card>
