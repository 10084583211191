import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { recipeService } from 'src/app/services/recipe.service';
import { Recipe } from 'src/app/shared/recipe.model';

import {FloatLabelType} from '@angular/material/form-field';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-link-edit',
  templateUrl: './link-edit.component.html',
  styleUrls: ['./link-edit.component.css']
})
export class LinkEditComponent implements OnInit {
  //recipe: Recipe;
  recipeId: number;
  recipeCreated: Date;
  recipeUser: string;
  categories: string[];
  hideRequiredControl = new FormControl(false);
  recipeForm: FormGroup = new FormGroup({'name' : new FormControl(),'category' : new FormControl(),'link' : new FormControl()}); //Placeholder to avoid errors.
  initializing: boolean = true;

  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  constructor(private recipeService: recipeService, private router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) {
    
   }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        this.recipeId = +params['id'];
        this.initForm();
      }
    )
    
  }

  async initForm(){
    let recipe = await this.recipeService.getRecipe(this.recipeId, true);
    //Save some data for when recipe gets updates.
    this.recipeCreated = recipe.created;
    this.recipeUser = recipe.user;
    this.categories = this.recipeService.getCategories();

    this.recipeForm = new FormGroup({
      'name' : new FormControl(recipe.name, Validators.required),
      'category' : new FormControl(recipe.category, Validators.required),
      'link' : new FormControl(recipe.link, Validators.required)
    })
    this.initializing = false;
  }

  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  submitRecipe(){
    let recipe : Recipe = new Recipe(this.recipeId, this.recipeForm.get("name")?.value, true, this.recipeForm.get("link")?.value, [], [], this.recipeCreated, new Date(), this.recipeUser, this.recipeForm.get("category")?.value);
    this.recipeService.updateRecipe(recipe, true);
    this.router.navigate(['recipes/link', this.recipeId]);
  }
}
