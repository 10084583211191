<div *ngIf="!initializing && !editMode" class="scrollable-content"  id="content">
    <h2 mat-dialog-title>{{recipe.name}}</h2>
    <mat-dialog-content >
        <button (click)="goToLink()" class="launch-icon"  mat-icon-button>
            <mat-icon>link</mat-icon>
        </button>
        <span>{{recipe?.link}}</span>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button *ngIf="recipe?.user == user?.id?.toString()" (click)="editMode = true" mat-button>EDIT</button>
      <button *ngIf="!deleteButtonVerify && recipe?.user == user?.id?.toString()" (click)="deleteButtonVerify = true;" mat-button>DELETE</button>
      <button color="warn" *ngIf="deleteButtonVerify && recipe?.user == user?.id?.toString()" (click)="deleteRecipe()" mat-button>DELETE</button>
      <span style="margin-left: auto; color: rgb(125, 124, 98);  font-size: 20px;" >{{recipeAuthorName}}</span>
    </mat-dialog-actions>
    <button style="position: absolute; right: 10px; top: 10px;" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>

<div *ngIf="!initializing && editMode" class="scrollable-content" id="content">
    <mat-dialog-content class="example-container">
        <form [formGroup]="recipeForm" (ngSubmit)="submitRecipe()">
          <!-- <mat-checkbox [formControl]="hideRequiredControl">Hide required marker</mat-checkbox> -->
          <div class="example-form-fields">
            <!-- Title -->
            <mat-form-field
                [hideRequiredMarker]="hideRequiredControl.value"
                [floatLabel]="getFloatLabelValue()"
                style="display:block">
              <input matInput placeholder="Title" formControlName="name">
              <!-- <div *ngIf="!recipeForm.get('name')?.valid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)"
                class="alert">error</div> -->
                <mat-hint *ngIf="!recipeForm.get('name')?.valid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)">Error</mat-hint>
            </mat-form-field>

            <!-- Category -->
            <mat-form-field
             [hideRequiredMarker]="hideRequiredControl.value"
             [floatLabel]="getFloatLabelValue()">
              <mat-select 
              formControlName="category" 
              >
                <mat-option *ngFor="let category of categories" selected="true" value={{category}}>{{category}}</mat-option>
              </mat-select>
              <mat-label>
                <!-- <mat-icon>favorite</mat-icon>  -->
                <!-- <strong> Fancy</strong>  -->
                <em> Category</em></mat-label>
            </mat-form-field>

            <!-- Link text-->
            <mat-form-field style="display:block">
                <input matInput placeholder="Url" formControlName="link">
            </mat-form-field>

            <!-- Save and Cancel buttons -->
          </div>
          <mat-dialog-actions>
            <button routerLinkActive="active" [disabled]="!recipeForm.valid" type="submit" mat-button>SAVE CHANGES</button>
            <button type="button"  (click)="editMode = false" mat-button>CANCEL</button>
          </mat-dialog-actions>
        </form>
      </mat-dialog-content>

    </div>

