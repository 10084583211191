<div id="admin-content">
  <table *ngIf="!initializing" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
    
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
    
      <!-- RecipeCount Column -->
      <ng-container matColumnDef="recipeCount">
        <th mat-header-cell *matHeaderCellDef> Recipe Count </th>
        <td mat-cell *matCellDef="let element"> {{element.recipeCount}} </td>
      </ng-container>

      <!-- LinkCount Column -->
      <ng-container matColumnDef="linkCount">
        <th mat-header-cell *matHeaderCellDef> Link Count </th>
        <td mat-cell *matCellDef="let element"> {{element.linkCount}} </td>
      </ng-container>
    
      <!-- LastActive Column -->
      <ng-container matColumnDef="lastActive">
        <th mat-header-cell *matHeaderCellDef> Last Activity </th>
        <td mat-cell *matCellDef="let element"> {{element.lastActive}} </td>
      </ng-container>

        <!-- Role Column -->
      <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> Role </th>
          <td mat-cell *matCellDef="let element"> 
            <mat-form-field>
              <!-- <mat-label>Favorite food</mat-label> -->
                <mat-select [(value)]="element.role">
                @for (role of roleList; track role) {
                  <mat-option (click)="updateRole(element)" [value]="role">{{role}}</mat-option>
                }
              </mat-select>
            </mat-form-field> 
          </td>
        </ng-container>
        <!-- GroupName Column -->
      <ng-container matColumnDef="groupName">
          <th mat-header-cell *matHeaderCellDef> Group </th>
          <td mat-cell *matCellDef="let element"> {{element.groupName}} </td>
        </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> Delete </th>
              <td mat-cell *matCellDef="let element"> 
                <button (click)="deleteClick(element)" mat-icon-button >
                <mat-icon>delete</mat-icon>
              </button> </td>
            </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <hr />
    <h2>Statistics</h2>
    <p>Total Recipes: {{totalRecipes}}</p>
    <p>Links: {{linkCount}} ({{linkCountPercent}})%</p>
    <p>Manual: {{manualCount}} ({{manualCountPercent}})%</p>
    <p>Total Groups: {{totalGroups}}</p>
    <p>Active Users (logged in in past month): {{activeUsers}}</p>
    <hr />
    <h2>Recipe's Created by Month</h2>
    <app-recipes-viz></app-recipes-viz>
    <hr />
    <h2>Logins by Month</h2>
    <app-logins-viz></app-logins-viz>
</div>
