<div id="about-content">
    <h2>Welcome to Grandma Ellinor's Recipes</h2>

    <p>Grandma Ellinor's Recipes is a web site used for saving family recipes or any recipes
        you want to remember. This is similar to a recipe book (Or recipe tin) that previous generations
        used. 
        </p>
    <p>
        Friends, family, and anyone who comes accross this web application may use it. Please don't hack it or use it for anything other than its
        intended purpose. By using it you agree to that. As of publishing (June 2024), it's still in beta and you may report any bugs you
        find here: GrandmaEllinorsRecipes&#64;gmail.com
        </p>
        <p>
        Use at your own risk. Backups are not kept and system errors may occur.
    </p>
    <p>
        -Jacob
    </p>

    <button style="background-color: rgb(235, 235, 210);" mat-raised-button [routerLink]="['/login']">Return to Login</button>
</div>