import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string = '';//'test@test.com';
  password: string = '';//'test';
  errorMessage: string = '';
  loading: boolean = false;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.auth.logout();
  }

  async login() {
    this.loading = true;
    let res = await this.auth.login(this.email, this.password);
    if(res){
      this.loading = false;
      this.router.navigate(['/recipes'])
    } else {
      this.loading = false;
      this.errorMessage = 'No user found with that username and password';
    }
  }

  async forgotPassword() {
    this.loading = true;
    let res = await this.auth.forgotPassword(this.email);
      if(res.success){
        this.loading = false;
        alert(res.message);
      } else {
        this.loading = false;
        alert(res.message);
      }
    }
}
