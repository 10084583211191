import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  email: string = '';
  password: string = '';
  firstName: string = '';
  lastName: string = '';
  errorMessage: string = '';
  loading: boolean = false;

  constructor(private auth : AuthService, private router: Router, private zone: NgZone) { }

  ngOnInit(): void {
    this.auth.logout();
  }


  async signup() {
    this.loading = true;
    let res = await this.auth.signUp(this.email, this.password, this.firstName, this.lastName);
    if(res){
      this.loading = false;
      this.router.navigate(['/recipes'])
    } else {
      this.loading = false;
      this.errorMessage = 'Unable to register';
    }
    
  }

  signupSuccess(res:boolean){
    alert('back in signup function');
      //if(res === true){
        //this.zone.run(() => this.router.navigate(['/recipes']));
        this.router.navigate(['/recipes'])
      //}
      
  }

  signupFailure(res:boolean){
    alert('in failure funcdtion');
  }
}
