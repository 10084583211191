import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { recipeService } from 'src/app/services/recipe.service';
import { Recipe } from 'src/app/shared/recipe.model';
import { User } from 'src/app/shared/user.model';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Ingredient } from 'src/app/shared/ingredient.model';
import { Step } from 'src/app/shared/step.model';

@Component({
  selector: 'app-recipe-detail-dialog',
  templateUrl: './recipe-detail-dialog.component.html',
  styleUrl: './recipe-detail-dialog.component.css'
})
export class RecipeDetailDialogComponent implements OnInit {

  //Recipe Details component variables
  recipe: Recipe;
  user: User | null;
  deleteButtonVerify: boolean = false;
  initializing: boolean = true;
  recipeAuthorName : string;
  editMode : boolean = false;

  //Edit component variables
  recipeId: number;
  recipeCreated: Date;
  recipeUser: string;
  categories: string[];
  hideRequiredControl = new FormControl(false);
  recipeForm: FormGroup = new FormGroup({name: new FormControl(), category: new FormControl(), ingredients: new FormControl(), steps: new FormControl()}); //placeholder to wait for async result.

  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  constructor(private recipeService: recipeService, private auth: AuthService, @Inject(MAT_DIALOG_DATA) public data: {recipeId: number}, public dialogRef : MatDialogRef<RecipeDetailDialogComponent>, private _formBuilder: FormBuilder) { }
  
  ngOnInit(): void {
    this.recipeService.getRecipe(this.data.recipeId, false).then(this.finishInit.bind(this));
  }

  async finishInit(value : Recipe){
    this.recipe = value
    this.recipeId = value.id;
    this.user = await this.auth.getCurrentUser();
    let groupUsers = await this.auth.getGroupUsers();
    this.recipeAuthorName = this.getUserFromId(+this.recipe.user, groupUsers).firstName[0] + ' ' + this.getUserFromId(+this.recipe.user, groupUsers).lastName;
    
    //Edit Component code
    this.recipeCreated = this.recipe.created;
    this.recipeUser = this.recipe.user;
    this.categories = this.recipeService.getCategories();

    let recipeIngredients = new FormArray<any>([]);
    let recipeSteps = new FormArray<any>([]);

    for(let ingredient of this.recipe.ingredients){
      recipeIngredients.push(new FormGroup({
        'amount' : new FormControl(ingredient.amount),
        'name' : new FormControl(ingredient.name, Validators.required)
      }));
    }

    for(let step of this.recipe.steps){
      recipeSteps.push(new FormGroup({
        'description' : new FormControl(step.description, Validators.required)
      }));
    }

    this.recipeForm = new FormGroup({
      'name' : new FormControl(this.recipe.name, Validators.required),
      'category' : new FormControl(this.recipe.category, Validators.required),
      'ingredients' : recipeIngredients,
      'steps' : recipeSteps
    })
    
    this.initializing = false;
  }

  getUserFromId(id : number, groupUsers : User[]){
    for(let cur of groupUsers){
      if(cur.id == id){
        return cur;
      }
    }
    return new User(0, '', '', '', '', '', '','');
  }

  deleteRecipe(){
    this.recipeService.deleteRecipe(this.recipe.id, false);
  }

  ///////////////////////Recipe Edit Code/////////////////////////

  
  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  dropIngredient(event: CdkDragDrop<string[]>) {
    this.move((<FormArray>this.recipeForm.get('ingredients')), event.previousIndex, event.currentIndex);
  }

  dropStep(event: CdkDragDrop<string[]>) {
    this.move((<FormArray>this.recipeForm.get('steps')), event.previousIndex, event.currentIndex);
  }

  deleteIngredient(index: number){
    (<FormArray>this.recipeForm.get('ingredients')).removeAt(index);
  }

  deleteStep(index: number){
    (<FormArray>this.recipeForm.get('steps')).removeAt(index);
  }

  addIngredient(){
    (<FormArray>this.recipeForm.get('ingredients')).push( new FormGroup({
        'amount' : new FormControl('', Validators.required),
        'name' : new FormControl('', Validators.required)
    }))
  }

  addStep(){
    (<FormArray>this.recipeForm.get('steps')).push( new FormGroup({
      'description' : new FormControl('', Validators.required)
    }))
  }

  get ingredients() {
    return this.recipeForm.get('ingredients') as FormArray;
  }

  get steps() {
    return this.recipeForm.get('steps') as FormArray;
  }

  submitRecipe(){
    let ingredients : Ingredient[] = [];
    let steps : Step[] = [];
    (<FormArray>(this.recipeForm.get("ingredients"))).controls.forEach(function (curr, i) {
      ingredients.push(new Ingredient(curr.value["name"], curr.value["amount"]));
    });
    (<FormArray>(this.recipeForm.get("steps"))).controls.forEach(function (curr, i) {
      steps.push(new Step(curr.value["description"]));
    })

    let recipe : Recipe = new Recipe(this.recipeId, this.recipeForm.get("name")?.value, false, '', ingredients, steps, this.recipeCreated, new Date(), this.recipeUser, this.recipeForm.get("category")?.value);
    this.recipeService.updateRecipe(recipe, false);
    this.dialogRef.close();
  }

  move(list : FormArray, prevIndex : number, newIndex : number){
    if(prevIndex == newIndex) return;
    if(prevIndex < newIndex){
      let ctl : AbstractControl = list.at(prevIndex);
      while(prevIndex < newIndex){
        list.setControl(prevIndex, list.at(prevIndex + 1));
        prevIndex = prevIndex + 1;
      }
      list.setControl(prevIndex, ctl);
    }
    if(prevIndex > newIndex){
      let ctl : AbstractControl = list.at(prevIndex);
      while(prevIndex > newIndex){
        list.setControl(prevIndex, list.at(prevIndex - 1));
        prevIndex = prevIndex - 1;
      }
      list.setControl(prevIndex, ctl);
    }
  }
}
