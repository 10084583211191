import { Pipe, PipeTransform } from '@angular/core';
import { Recipe } from '../shared/recipe.model';
import { User } from '../shared/user.model';

@Pipe({
  name: 'filterAuthor'
})
export class FilterAuthorPipe implements PipeTransform {

  transform(items: Recipe[], filter: number, users: User[], currentUserId?: number): any {
    //console.log('filter is: ' + filter);
    if (!items || !filter || filter == 0) {
      return items;
    }

    if(filter == 0){
      return items;
    }
    if(filter == 1){
      return items.filter(item => +item.user  == currentUserId);
    }
    return items.filter(item => +item.user  == users[filter-1].id);
  }

}
