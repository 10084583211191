import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { recipeService } from 'src/app/services/recipe.service';
import { Recipe } from 'src/app/shared/recipe.model';
import { User } from 'src/app/shared/user.model';
import {MatDialog} from '@angular/material/dialog';
import { RecipeDetailDialogComponent } from '../recipe-detail/recipe-detail-dialog/recipe-detail-dialog.component';
import { LinkDetailDialogComponent } from '../link-detail/link-detail-dialog/link-detail-dialog.component';

@Component({
  selector: 'app-my-recipes',
  templateUrl: './my-recipes.component.html',
  styleUrls: ['./my-recipes.component.css']
})
export class MyRecipesComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  recipes : Recipe[];
  categories: string[];
  users: User[];
  sortOptions: string[] = ['Alphabetical','Created Date','Last Edited Date','Kind'];
  filterOptions: string[] = ['All Recipes','My Recipes'];
  sortValue: string;
  filterValue: number;
  currentUserId?: number;
  smallScreen: boolean = false;

  constructor(private recipeService : recipeService, private authService : AuthService, private router : Router, public dialog: MatDialog) { 
    this.accordion = new MatAccordion();
    this.recipeService.recipeListChanged.subscribe((params) => {
      this.recipeService.getRecipes().then(this.getRecipesCallback.bind(this));
      });

      authService.LoggedIn.subscribe(() => {
          if( authService.isLoggedIn()){
            this.authService.getGroupUsers().then(this.getUsersCallback.bind(this));
          }
      })
  }

  ngOnInit(): void {
    if(screen.width < 600) {this.smallScreen = true;}
    this.authService.getCurrentUser().then(this.initializeCallback.bind(this));
  }

  initializeCallback(value : User | null){
    this.currentUserId = value?.id;
    this.filterValue = value?.filter ? +value?.filter : 0;
    this.recipeService.getRecipes().then(this.getRecipesCallback.bind(this));
    this.categories = this.recipeService.getCategories();
    this.authService.getGroupUsers().then(this.getUsersCallback.bind(this));
  }

  getRecipesCallback(value : Recipe[]) {
    this.recipes = value;
  }

  getUsersCallback(value : User[]){
    this.users = value;
    this.filterOptions = ['All Recipes','My Recipes'];
    for(let current of this.users){
      if(current.id == this.currentUserId){
        continue;
      }
      this.filterOptions.push(current.firstName + ' ' + current.lastName + "'s Recipes");
    }
  }

  onSortClick(option: string){
    this.sortValue = option;
    this.sortRecipes();
    //TODO - Save to user service
  }

  onFilterClick(option: number){
    // let id = -2; //Means error
    // let firstName = option.split(' ')[0];
    // let lastName = option.split(' ')[1];
    // for(let curr of this.users){
    //   if(curr.firstName == firstName && curr.lastName == lastName){
    //     id = curr.id;
    //     break;
    //   }
    // }
    this.filterValue = option;
    this.authService.updateFilterChoice(option);
  }

  sortRecipes() {
    console.log('sorting recipes by: ' + this.sortValue);
    switch(this.sortValue){
      case 'Alphabetical':
        console.log('inside alphabetical');
        this.recipes = this.recipes.sort((a, b) => a.name.localeCompare(b.name)).slice()
        break;
      case 'Created Date':
        this.recipes = this.recipes.sort((a, b) =>  a.created.getTime() - b.created.getTime()).slice()
        break;
      case  'Last Edited Date':
        this.recipes = this.recipes.sort((a, b) =>  a.lastUpdated.getTime() - b.lastUpdated.getTime()).slice()
        break;
      case 'Kind':
        this.recipes = this.recipes.sort((a, b) => a.isLink ? 1 : -1).slice()
        break;
    }
  }

  openRecipeDialog(recipeId : number) {
    const dialogRef = this.dialog.open(RecipeDetailDialogComponent, {
      data: { recipeId: recipeId  },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openLinkDialog(recipeId : number) {
    const dialogRef = this.dialog.open(LinkDetailDialogComponent, {
      data: { recipeId : recipeId },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
