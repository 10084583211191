<div id="account-content">
    <h2>Name: {{firstName + ' ' + lastName}}</h2>
    <h2>Role: {{role}}</h2>
    <hr />
    <form id="group-form">
        <h2>Change Group</h2>
        <mat-form-field class="example-full-width">
            <mat-label>Group Name</mat-label>
            <input #groupInput matInput placeholder="" [value]='groupName'>
        </mat-form-field>
        <button *ngIf="!loading" type="button" class="group-btn" mat-raised-button (click)="updateGroup(groupInput.value)">Update Group</button>
        <button *ngIf="loading" type="button" class="group-btn" mat-raised-button><img class="loading-gif" src="assets/Rhombus.gif"></button>
        <mat-icon style="vertical-align: middle; margin-left: 10px;" matTooltip="Use the same group as friends and family to see their recipes">info</mat-icon>
    </form>
    <hr />
    <!-- Change Password form -->
    <form id="change-password-form">
        <h2>Change Password</h2>
        <mat-form-field class="example-full-width">
            <mat-label>Old Password</mat-label>
            <input #oldPasswordInput matInput placeholder="" [value]=''>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>New Password</mat-label>
            <input #newPasswordInput matInput placeholder="" [value]=''>
        </mat-form-field>
        <button *ngIf="!loading" type="button" class="group-btn" mat-raised-button (click)="updatePassword(oldPasswordInput.value, newPasswordInput.value)">Update Password</button>
        <button *ngIf="loading" type="button" class="group-btn" mat-raised-button><img class="loading-gif" src="assets/Rhombus.gif"></button>
    </form>
    <hr />
    <button type="button" id="logout-btn" mat-raised-button (click)="logoutBtnClick()" >Log Out</button>
    
</div>
