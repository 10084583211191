import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { AuthService } from 'src/app/services/auth.service';
import { recipeService } from 'src/app/services/recipe.service';

@Component({
  selector: 'app-recipes-viz',
  templateUrl: './recipes-viz.component.html',
  styleUrl: './recipes-viz.component.css'
})
export class RecipesVizComponent implements OnInit {

  // data = [
  //   {'month' : 'May 2024', 'count' : 10},
  //   {'month' : 'April 2024', 'count' : 1},
  //   {'month' : 'March 2024', 'count' : 5}
  // ]

  constructor(private auth : AuthService, private recipeService : recipeService){}

  ngOnInit(){
    this.recipeService.getRecipesByYear().then(this.barInit.bind(this));
  }

  barInit(data : any[]){
    // set the dimensions and margins of the graph
    const margin = {top: 30, right: 30, bottom: 70, left: 60},
    width = 460 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3.select("#recipes-viz-container")
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

    // X axis
    const x = d3.scaleBand()
    .range([ 0, width ])
    .domain(data.map(d => d.month))
    .padding(0.2);
    svg.append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(x))
    .selectAll("text")
    .attr("transform", "translate(-10,0)rotate(-45)")
    .style("text-anchor", "end");

    // Add Y axis
    const y = d3.scaleLinear()
    .domain([0, 100])
    .range([ height, 0]);
    svg.append("g")
    .call(d3.axisLeft(y));

    // Bars
    svg.selectAll("mybar")
    .data(data)
    .join("rect")
    .attr("x", d => x(d.month)!)
    .attr("y", d => y(d.count))
    .attr("width", x.bandwidth())
    .attr("height", d => height - y(d.count))
    .attr("fill", "#69b3a2")
  }
}
