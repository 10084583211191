<mat-card *ngIf="!initializing" class="scrollable-content" id="content">
    <div class="example-container">
        <form [formGroup]="recipeForm" (ngSubmit)="submitRecipe()">
          <!-- <mat-checkbox [formControl]="hideRequiredControl">Hide required marker</mat-checkbox> -->
          <div class="example-form-fields">
            <!-- Title -->
            <mat-form-field
                [hideRequiredMarker]="hideRequiredControl.value"
                [floatLabel]="getFloatLabelValue()"
                style="display:block">
              <input matInput placeholder="Title" formControlName="name">
              <!-- <div *ngIf="!recipeForm.get('name')?.valid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)"
                class="alert">error</div> -->
                <mat-hint *ngIf="!recipeForm.get('name')?.valid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)">Error</mat-hint>
            </mat-form-field>

            <!-- Category -->
            <mat-form-field
             [hideRequiredMarker]="hideRequiredControl.value"
             [floatLabel]="getFloatLabelValue()">
              <mat-select 
              formControlName="category" 
              >
                <mat-option *ngFor="let category of categories" selected="true" value={{category}}>{{category}}</mat-option>
              </mat-select>
              <mat-label>
                <!-- <mat-icon>favorite</mat-icon>  -->
                <!-- <strong> Fancy</strong>  -->
                <em> Category</em></mat-label>
            </mat-form-field>

            <!-- Ingredients -->
              <div cdkDropList  (cdkDropListDropped)="dropIngredient($event)" class="example-list">
                <div formArrayName="ingredients">
                  <div class="example-box"  *ngFor="let ingredientCtl of ingredients.controls; let i = index" [formGroupName]="i" cdkDrag>
                    <mat-form-field
                      class="quantity"
                      [hideRequiredMarker]="hideRequiredControl.value">
                      <input matInput placeholder="Quantity" formControlName="amount">
                    </mat-form-field>
                    <mat-form-field
                      class="ingredient"
                      [hideRequiredMarker]="hideRequiredControl.value">
                      <input matInput placeholder="Ingredient" formControlName="name">
                    </mat-form-field>
                    <!-- drag element -->
                    <div class="example-handle" cdkDragHandle>
                      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </div>
                    <button type="button" (click)="deleteIngredient(i)" mat-icon-button color="warn" aria-label="Delete icon">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
              </div>
            </div>
            <div style="display: block; width: 100%; text-align: center;">
              <button type="button" (click)="addIngredient()" style="margin: auto" mat-icon-button color="primary" aria-label="Ingredient add button">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <!-- end ingredient section -->
            <hr>
            <!-- Steps section with moveable list -->
              <div cdkDropList class="example-list" (cdkDropListDropped)="dropStep($event)">
                <div formArrayName="steps">
                  <div class="example-box step-box" *ngFor="let stepCtl of steps.controls; let i = index"  [formGroupName]="i" cdkDrag>
                    <mat-form-field
                      class="step-content"
                      [hideRequiredMarker]="hideRequiredControl.value">
                      <textarea class="step-text" matInput placeholder="Step" formControlName="description"></textarea>
                    </mat-form-field>
                    
                    <!-- drag element -->
                    <div class="example-handle" cdkDragHandle>
                      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </div>
                    <button type="button" (click)="deleteStep(i)" mat-icon-button color="warn" aria-label="Example icon button with a home icon">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
              </div>
            </div>
            <div style="display: block; width: 100%; text-align: center;">
              <button type="button" (click)="addStep()" style="margin: auto" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <hr>
            <!-- Save and Cancel buttons -->
          </div>
          <mat-card-actions>
            <button routerLinkActive="active" [disabled]="!recipeForm.valid" type="submit" mat-button>SAVE CHANGES</button>
            <button type="button"  [routerLink]="['/recipes', recipeId]" mat-button>CANCEL</button>
          </mat-card-actions>
        </form>
      </div>

  </mat-card>
