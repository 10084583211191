import { Pipe, PipeTransform } from '@angular/core';
import { Recipe } from '../shared/recipe.model';

@Pipe({
  name: 'filterCategory'
})
export class FilterCategoryPipe implements PipeTransform {
  transform(items: Recipe[], filter: string): any {
    //console.log('filter is: ' + filter);
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => item.category == filter);
  }

}
