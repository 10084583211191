import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { recipeService } from 'src/app/services/recipe.service';
import { Recipe } from 'src/app/shared/recipe.model';
import { User } from 'src/app/shared/user.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'app-link-detail-dialog',
  templateUrl: './link-detail-dialog.component.html',
  styleUrl: './link-detail-dialog.component.css'
})
export class LinkDetailDialogComponent {

  recipe: Recipe;
  user: User | null;
  deleteButtonVerify: boolean = false;
  initializing : boolean = true;
  recipeAuthorName : string;
  editMode : boolean = false;

  recipeId: number;
  recipeCreated: Date;
  recipeUser: string;
  categories: string[];
  hideRequiredControl = new FormControl(false);
  recipeForm: FormGroup = new FormGroup({'name' : new FormControl(),'category' : new FormControl(),'link' : new FormControl()}); //Placeholder to avoid errors.

  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  constructor(private recipeService: recipeService, private router: Router, private route: ActivatedRoute,  @Inject(MAT_DIALOG_DATA) public data: {recipeId: number}, public dialogRef : MatDialogRef<LinkDetailDialogComponent>, private auth: AuthService, private _formBuilder: FormBuilder) {
    
   }

  ngOnInit(): void {
    this.recipeService.getRecipe(this.data.recipeId, true).then(this.finishInit.bind(this));
  }

  async finishInit(value : Recipe){
    this.recipe = value;

    this.user = await this.auth.getCurrentUser();
    let groupUsers = await this.auth.getGroupUsers();
    this.recipeAuthorName = this.getUserFromId(+this.recipe.user, groupUsers).firstName[0] + ' ' + this.getUserFromId(+this.recipe.user, groupUsers).lastName;
    
    //Link Edit stuff
    //Save some data for when recipe gets updates.
    this.recipeCreated = this.recipe.created;
    this.recipeUser = this.recipe.user;
    this.categories = this.recipeService.getCategories();
    this.recipeId = this.recipe.id;

    this.recipeForm = new FormGroup({
      'name' : new FormControl(this.recipe.name, Validators.required),
      'category' : new FormControl(this.recipe.category, Validators.required),
      'link' : new FormControl(this.recipe.link, Validators.required)
    })
    
    this.initializing = false;
  }

  getUserFromId(id : number, groupUsers : User[]){
    for(let cur of groupUsers){
      if(cur.id == id){
        return cur;
      }
    }
    return new User(0, '', '', '', '', '', '','');
  }

  deleteRecipe(){
    this.recipeService.deleteRecipe(this.recipe.id, true);
    this.dialogRef.close();
  }

  goToLink(){
    window.open(this.recipe.link, '_blank');
  }

  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  submitRecipe(){
    let recipe : Recipe = new Recipe(this.recipeId, this.recipeForm.get("name")?.value, true, this.recipeForm.get("link")?.value, [], [], this.recipeCreated, new Date(), this.recipeUser, this.recipeForm.get("category")?.value);
    this.recipeService.updateRecipe(recipe, true);
    this.dialogRef.close();
  }

}
