<mat-card *ngIf="!initializing" class="scrollable-content"  id="content">
    <mat-card-header>
      <mat-card-title>{{recipe?.name}}</mat-card-title>
      <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
    </mat-card-header>
    <mat-card-content>
      <p class="ingredient" *ngFor="let ingredient of recipe?.ingredients">{{ingredient.amount}} {{ingredient.name}}</p>
      <hr>
      <p *ngFor="let step of recipe?.steps; let i = index">{{i + 1}}. {{step.description}}</p>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf = "recipe?.user == user?.id?.toString()" routerLinkActive="active" [routerLink]="['/recipes/edit', recipe?.id]" mat-button>EDIT</button>
      <button *ngIf="!deleteButtonVerify && recipe?.user == user?.id?.toString()" (click)="deleteButtonVerify = true;" mat-button>DELETE</button>
      <button color="warn" *ngIf="deleteButtonVerify && recipe?.user == user?.id?.toString()" (click)="deleteRecipe()" mat-button>DELETE</button>
      <span style="margin-left: auto; color: rgb(125, 124, 98); font-size: 20px;" >{{recipeAuthorName}}</span>
    </mat-card-actions>
  </mat-card>
  
