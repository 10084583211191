import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { recipeService } from 'src/app/services/recipe.service';
import { Recipe } from 'src/app/shared/recipe.model';

import {FloatLabelType} from '@angular/material/form-field';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Ingredient } from 'src/app/shared/ingredient.model';
import { Step } from 'src/app/shared/step.model';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/shared/user.model';



@Component({
  selector: 'app-recipe-add',
  templateUrl: './recipe-add.component.html',
  styleUrls: ['./recipe-add.component.css']
})
export class RecipeAddComponent implements OnInit {
  //recipe: Recipe;
  recipeCreated: Date;
  recipeUser?: string;
  categories: string[];
  hideRequiredControl = new FormControl(false);
  recipeForm: FormGroup;
  loading: boolean = false;
  initializing: boolean = true;

  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  constructor(private recipeService: recipeService, private auth : AuthService, private router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) {
    
   }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.auth.getCurrentUser().then(this.initializeCallback.bind(this));
  }

  initializeCallback(value : User | null){
      //Save some data for when recipe gets updates.
    this.recipeCreated = new Date();
    //console.log(this.auth.getCurrentUser());
    this.recipeUser = value?.id.toString().toString();
    this.categories = this.recipeService.getCategories();
    

    let recipeIngredients = new FormArray<any>([]);
    let i = 0;
    while(i < 3){ //Show some empty boxes in UI
      recipeIngredients.controls.push(new FormGroup({
        'amount' : new FormControl(''),
        'name' : new FormControl('', Validators.required)
      }));
      i++;
    }
    let recipeSteps = new FormArray<any>([]);
    i = 0;
    while(i < 3){ //Show some empty boxes in UI
      recipeSteps.controls.push(new FormGroup({
        'description' : new FormControl('', Validators.required)
      }));
      i++;
    }


    this.recipeForm = new FormGroup({
      'name' : new FormControl('', Validators.required),
      'category' : new FormControl('', Validators.required),
      'ingredients' : recipeIngredients,
      'steps' : recipeSteps
    })
    this.initializing = false;
  }

  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  dropIngredient(event: CdkDragDrop<string[]>) {
    this.move((<FormArray>this.recipeForm.get('ingredients')), event.previousIndex, event.currentIndex);
  }

  dropStep(event: CdkDragDrop<string[]>) {
    this.move((<FormArray>this.recipeForm.get('steps')), event.previousIndex, event.currentIndex);
  }

  deleteIngredient(index: number){
    (<FormArray>this.recipeForm.get('ingredients')).removeAt(index);
  }

  deleteStep(index: number){
    (<FormArray>this.recipeForm.get('steps')).removeAt(index);
  }

  addIngredient(){
    (<FormArray>this.recipeForm.get('ingredients')).push( new FormGroup({
        'amount' : new FormControl(''),
        'name' : new FormControl('', Validators.required)
    }))
  }

  addStep(){
    (<FormArray>this.recipeForm.get('steps')).push( new FormGroup({
      'description' : new FormControl('', Validators.required)
    }))
  }

  get ingredients() {
    return this.recipeForm.get('ingredients') as FormArray;
  }

  get steps() {
    return this.recipeForm.get('steps') as FormArray;
  }

  async submitRecipe(){
    this.loading = true;
    let ingredients : Ingredient[] = [];
    let steps : Step[] = [];
    (<FormArray>(this.recipeForm.get("ingredients"))).controls.forEach(function (curr, i) {
      ingredients.push(new Ingredient(curr.value["name"], curr.value["amount"]));
    });
    (<FormArray>(this.recipeForm.get("steps"))).controls.forEach(function (curr, i) {
      steps.push(new Step(curr.value["description"]));
    })

    let recipe : Recipe = new Recipe(-1, this.recipeForm.get("name")?.value, false, '', ingredients, steps, this.recipeCreated, new Date(), this.recipeUser ?? '', this.recipeForm.get("category")?.value);
    let recipeId = await this.recipeService.createRecipe(recipe);
    if(recipeId == 0) {
      this.loading = false;
      alert('Unable to create recipe');
    }
    else {
      this.loading = false;
      if(screen.width < 600) {
        this.router.navigate(['recipes']);
      } else {
        this.router.navigate([]);
        this.router.navigate(['recipes', recipeId]);
      }
    }
  }

  move(list : FormArray, prevIndex : number, newIndex : number){
    if(prevIndex == newIndex) return;
    if(prevIndex < newIndex){
      let ctl : AbstractControl = list.at(prevIndex);
      while(prevIndex < newIndex){
        list.setControl(prevIndex, list.at(prevIndex + 1));
        prevIndex = prevIndex + 1;
      }
      list.setControl(prevIndex, ctl);
    }
    if(prevIndex > newIndex){
      let ctl : AbstractControl = list.at(prevIndex);
      while(prevIndex > newIndex){
        list.setControl(prevIndex, list.at(prevIndex - 1));
        prevIndex = prevIndex - 1;
      }
      list.setControl(prevIndex, ctl);
    }
  }
}
